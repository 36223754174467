export const REGIONS_LIST = [
    {
        "key": "3866",
        "name": "Minnesota",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3855",
        "name": "Idaho",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3856",
        "name": "Illinois",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3864",
        "name": "Massachusetts",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3846",
        "name": "Arkansas",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3886",
        "name": "Texas",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3843",
        "name": "Alabama",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3882",
        "name": "Rhode Island",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3862",
        "name": "Maine",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3873",
        "name": "New Jersey",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3885",
        "name": "Tennessee",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3887",
        "name": "Utah",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3888",
        "name": "Vermont",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3861",
        "name": "Louisiana",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3859",
        "name": "Kansas",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3876",
        "name": "North Carolina",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3857",
        "name": "Indiana",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3890",
        "name": "Washington",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3889",
        "name": "Virginia",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3870",
        "name": "Nebraska",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3849",
        "name": "Connecticut",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3845",
        "name": "Arizona",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3844",
        "name": "Alaska",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3853",
        "name": "Georgia",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3863",
        "name": "Maryland",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3867",
        "name": "Mississippi",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3877",
        "name": "North Dakota",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3872",
        "name": "New Hampshire",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3847",
        "name": "California",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3850",
        "name": "Delaware",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3875",
        "name": "New York",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3891",
        "name": "West Virginia",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3883",
        "name": "South Carolina",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3854",
        "name": "Hawaii",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3892",
        "name": "Wisconsin",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3884",
        "name": "South Dakota",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3858",
        "name": "Iowa",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3851",
        "name": "Washington D. C.",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3881",
        "name": "Pennsylvania",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3868",
        "name": "Missouri",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3865",
        "name": "Michigan",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3848",
        "name": "Colorado",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3879",
        "name": "Oklahoma",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3880",
        "name": "Oregon",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3871",
        "name": "Nevada",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3860",
        "name": "Kentucky",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3874",
        "name": "New Mexico",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3852",
        "name": "Florida",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3869",
        "name": "Montana",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3893",
        "name": "Wyoming",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    },
    {
        "key": "3878",
        "name": "Ohio",
        "type": "region",
        "country_code": "US",
        "country_name": "United States"
    }
]
