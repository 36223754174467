import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { TableColumn } from "../../../components/Table";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  { key: "createdDate", label: "Created Date" },
  { key: "status", label: "Status" },
];

const nbaBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/marketing-mix-model",
  },
  {
    name: "Marketing Mix Model",
    url: "/measurement/marketing-mix-model",
  },
];

const MarketingMixModel: React.FC = () => {
  const [jobConfigList, setJobConfigList] = useState([]);
  const [jobRunList, setJobRunList] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/api/jobConfig/jobType/MMM")
      .then((jobConfigListResponse) => {
        setJobConfigList(jobConfigListResponse.data.items);
      });
    axiosInstance.get("/api/jobRun/jobType/MMM").then((jobRunListResponse) => {
      setJobRunList(jobRunListResponse.data.items);
    });
  }, []);

  return (
    <>
      <JobConfigView
        jobConfigList={jobConfigList}
        jobRunList={jobRunList}
        breadCrumbs={nbaBreadCrumbs}
        tableColumns={columns}
        jobType={JobType.MarketingMixModel}
      />
    </>
  );
};

export default MarketingMixModel;
