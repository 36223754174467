import React from "react";
import ReactECharts from "echarts-for-react";

interface HeatmapProps {
  data: {
    xLabels: string[];
    yLabels: string[];
    zData: number[][];
  };
}

const HeatmapECharts: React.FC<HeatmapProps> = ({ data }) => {
  const { xLabels, yLabels, zData } = data;

  // Transform zData into the format required by ECharts
  const formattedData = zData.flatMap((row, rowIndex) =>
    row.map((value, colIndex) => [colIndex, rowIndex, value])
  );

  const option = {
    tooltip: {
      position: "top",
      formatter: (params: { value: [number, number, number] }) => {
        const value = params.value[2];
        return `${value.toFixed(2)}`;
      },
    },
    grid: {
      left: "200px",
      right: "200px",
      bottom: "150px",
      top: "50px",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xLabels,
      splitArea: {
        show: true,
      },
      axisLabel: {
        rotate: 45,
        interval: 0,
      },
    },
    yAxis: {
      type: "category",
      data: yLabels,
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: 1,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15px",
      inRange: {
        color: ["#e3ffee", "#02c24d"],
      },
    },
    series: [
      {
        name: "Heatmap",
        type: "heatmap",
        data: formattedData,
        label: {
          show: true,
          formatter: (params: { value: [number, number, number] }) => {
            const value = params.value[2];
            return value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: "800px", width: "100%" }} />
  );
};

export default HeatmapECharts;
