import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {

  const navigate = useNavigate();

  useEffect(() => {
    navigate("/measurement/marketing-mix-model");
  }, [navigate]);

  return (
    <>
      <NavBar></NavBar>
    </>
  );
};

export default Home;
