import React, { useState } from "react";
import { z } from "zod";
import DynamicForm, { ExtendedFieldConfig } from "../DynamicForm";
import NavBar from "../NavBar";
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from "../../utils/axiosInstance";
import { JobType } from "../../constants/JobTypes";
import { useNavigate } from "react-router-dom";
import Papa from 'papaparse';
import { DMA_LIST } from "../../constants/DMAList";
import { REGIONS_LIST } from "../../constants/RegionsList";
import { cloneDeep } from 'lodash';
import { LOCALE_LIST } from "../../constants/LocaleList";

const bidOptimizerSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z
      .string()
      .min(1, "Name is required")
      .max(100, "Name cannot exceed 100 characters"),
    metadata: {
      type: "text", label: "Name", group: "row1",
      fieldInfo: "Name of the Job to identify in Jobs list"
    },
  },
  campaignObjective: {
    validation: z.string(),
    metadata: {
      type: "select", label: "Campaign Objective", group: "row1", options: [
        {
          label: "Lead Generation",
          value: "lead"
        },
        {
          label: "Sales",
          value: "sales"
        },
        {
          label: "Reach",
          value: "reach"
        }
      ],
      fieldInfo: "Objective of the campaign to better reach and cost optmization"
    },
  },
  dataFile: {
    validation: z.any().refine((file) => file && file.length > 0, {
      message: "Data CSV File is required",
    }),
    metadata: {
      type: "file",
      label: "Data CSV File",
      group: "row2",
      accepts: ".csv",
      showIf: [],
      fieldInfo: "CSV file on which you want to run the model",
    },
  },
  userId: {
    validation: z.string().min(1, "Please select Customer Id variable"),
    metadata: {
      type: "select",
      label: "Customer Identifier",
      options: [],
      group: "row2a",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Customer Id for unique identification of records. Eg: Id or email",
    },
  },
  targetVariable: {
    validation: z.string().min(1, "Please select Target variable"),
    metadata: {
      type: "select",
      label: "Target Variable",
      options: [],
      group: "row2a",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables. Eg: Sales",

    },
  },
  age: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Age Range",
      options: [],
      group: "row3",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Column name that has Age range for a record in you CSV file",
    },
  },
  gender: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Gender",
      options: [],
      group: "row3",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Column name that has gender for a record in you CSV file",
    },
  },
  locale: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Locale",
      options: [],
      group: "row3",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Column name that has Locale for a record in you CSV file",
    },
  },
  geoLocationType: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Geo Location Type",
      options: [
        {
          label: "Designated Market Area",
          value: "dma"
        },
        {
          label: "Regions",
          value: "region"
        }
      ],
      group: "row4",
      showIf: [],
      fieldInfo: "Select the geo location type",
    },
  },
  geoLocation: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Geo Location",
      options: [],
      group: "row4",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Column name that has Geo Location for a record in you CSV file",
    },
  },
  // countryName: {
  //   validation: z.any(),
  //   metadata: {
  //     type: "select",
  //     label: "Country Name",
  //     options: [],
  //     group: "row4",
  //     dynamicOoptionDefault: "Empty",
  //     dynamicOptions: {
  //       field: "dataFile",
  //     },
  //     showIf: [],
  //   },
  // },
  // cityName: {
  //   validation: z.any(),
  //   metadata: {
  //     type: "select",
  //     label: "City Name",
  //     options: [],
  //     group: "row4",
  //     dynamicOoptionDefault: "Empty",
  //     dynamicOptions: {
  //       field: "dataFile",
  //     },
  //     showIf: [],
  //   },
  // },
  devicePlatform: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Device Platform",
      options: [],
      group: "row5",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Column name that has Device Platform for a record in you CSV file",
    },
  },
  userOs: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "User OS",
      options: [],
      group: "row5",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Column name that has User OS for a record in you CSV file",
    },
  },
  userDevice: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "User Device",
      options: [],
      group: "row5",
      dynamicOoptionDefault: "Empty",
      dynamicOptions: {
        field: "dataFile",
      },
      showIf: [],
      fieldInfo: "Column name that has User Device for a record in you CSV file",
    },
  },
};

const BidOptimizer: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isAlert, setAlert] = useState(false);
  const onSubmit = async (data: Record<string, any>) => {
    try {
      console.log(data);
      setLoading(true);

      const jobId = uuidv4();
      const bidMultiplierFormData = new FormData();

      bidMultiplierFormData.append('file', data.dataFile[0]);
      const mmmFilePath = jobId + '/input/bid_multiplier_data.csv';
      bidMultiplierFormData.append('path', mmmFilePath);
      await axiosInstance.post('/api/s3/upload', bidMultiplierFormData);


      const payload = {
        jobConfigId: jobId,
        name: data.name,
        jobType: JobType.BidOptimizer,
        dataSourceConfig: {
          dataSourceType: "FILE_CSV"
        },
        dataTransformationConfig: {},
        dataMappingConfig: {
          mappings: {
            bidMultiplier: {
              columnMapping: {
                age_varialbes: !data.age ? undefined : [data.age],
                // city_varialbes: !data.cityName ? undefined : [data.cityName],
                // country_varialbes: !data.countryName ? undefined : [data.countryName],
                device_platform_varialbes: !data.devicePlatform ? undefined : [data.devicePlatform],
                gender_varialbes: !data.gender ? undefined : [data.gender],
                locale_varialbes: !data.locale ? undefined : [data.locale],
                region_varialbes: !data.geoLocation ? undefined : [data.geoLocation],
                user_devices_varialbes: !data.userDevice ? undefined : [data.userDevice],
                user_os_varialbes: !data.userOs ? undefined : [data.userOs],
                target_variables: !data.targetVariable ? undefined : [data.targetVariable]

              }
            }
          }
        },
        modelVersion: "V1",
        modelParameterConfig: {
          parameters: {
            campaignObjective: data.campaignObjective,
            geoLocationType: data.geoLocationType
          }
        },
        modelOutputConfig: {}
      };

      // Send the POST request using Axios
      const response = await axiosInstance.post(
        "/api/jobConfig",
        payload
      );
      handleAlert();

      setLoading(false);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handleAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 1000);
  };

  const validateAge = (results: any, data: any, setError: any) => {
    const clonedResult = cloneDeep(results);
    const index = clonedResult.data[0].indexOf(data.age);
    clonedResult.data.shift();

    let validationResult = true;

    // Regular expression to match the "age1-age2" format
    const ageRangeRegex = /^(\d+-\d+|default)$/;

    clonedResult.data.some((element: any) => {
      const valueToValidate = element[index];

      console.log(valueToValidate);

      if (!ageRangeRegex.test(valueToValidate)) {
        validationResult = false;
        setError("form", {
          type: "manual",
          message: "Unsupported age value : " + element[index],
        });
        return true;
      }

      return false;
    });

    return validationResult;
  }

  const validateGender = (results: any, data: any, setError: any) => {
    const clonedResult = cloneDeep(results);
    const index = clonedResult.data[0].indexOf(data.gender);
    clonedResult.data.shift();

    let validationResult = true;

    // Regular expression to match the "age1-age2" format
    const genderRegex = /^(male|female|default)$/;

    clonedResult.data.some((element: any) => {
      const valueToValidate = element[index];

      console.log(valueToValidate);

      if (!genderRegex.test(valueToValidate)) {
        validationResult = false;
        setError("form", {
          type: "manual",
          message: "Unsupported gender value : " + element[index],
        });
        return true;
      }

      return false;
    });

    return validationResult;
  }

  const validateLocale = (results: any, data: any, setError: any) => {
    const clonedResult = cloneDeep(results);
    const index = clonedResult.data[0].indexOf(data.locale);
    clonedResult.data.shift();
    let validationResult = true;
    clonedResult.data.some((element: any) => {
      if (!LOCALE_LIST.map(item => item.name).includes(element[index])) {
        setError("form", {
          type: "manual",
          message: "Unsupported locale value : " + element[index],
        });
        validationResult = false;
        return true;
      }
      return false;
    });
    return validationResult;
  }

  const validateDevicePlatform = (results: any, data: any, setError: any) => {
    const clonedResult = cloneDeep(results);
    const index = clonedResult.data[0].indexOf(data.devicePlatform);
    clonedResult.data.shift();

    let validationResult = true;

    // Regular expression to match the "age1-age2" format
    const genderRegex = /^(mobile|desktop)$/;

    clonedResult.data.some((element: any) => {
      const valueToValidate = element[index];

      console.log(valueToValidate);

      if (!genderRegex.test(valueToValidate)) {
        validationResult = false;
        setError("form", {
          type: "manual",
          message: "Unsupported device platform value : " + element[index],
        });
        return true;
      }

      return false;
    });

    return validationResult;
  }

  const validateUserOs = (results: any, data: any, setError: any) => {
    const clonedResult = cloneDeep(results);
    const index = clonedResult.data[0].indexOf(data.userOs);
    clonedResult.data.shift();

    let validationResult = true;

    // Regular expression to match the "age1-age2" format
    const genderRegex = /^(Android|Windows|iOS|Windows Phone|default)$/;

    clonedResult.data.some((element: any) => {
      const valueToValidate = element[index];

      console.log(valueToValidate);

      if (!genderRegex.test(valueToValidate)) {
        validationResult = false;
        setError("form", {
          type: "manual",
          message: "Unsupported user os value : " + element[index],
        });
        return true;
      }

      return false;
    });

    return validationResult;
  }

  const validateUserDevice = (results: any, data: any, setError: any) => {
    const clonedResult = cloneDeep(results);
    const index = clonedResult.data[0].indexOf(data.userDevice);
    clonedResult.data.shift();

    let validationResult = true;

    // Regular expression to match the "age1-age2" format
    const genderRegex = /^(iPad|iPhone|default)$/;

    clonedResult.data.some((element: any) => {
      const valueToValidate = element[index];

      console.log(valueToValidate);

      if (!genderRegex.test(valueToValidate)) {
        validationResult = false;
        setError("form", {
          type: "manual",
          message: "Unsupported user os value : " + element[index],
        });
        return true;
      }

      return false;
    });

    return validationResult;
  }

  const validateRegion = (results: any, data: any, setError: any) => {
    const clonedResult = cloneDeep(results);
    const index = clonedResult.data[0].indexOf(data.geoLocation);
    clonedResult.data.shift();
    let validationResult = true;
    if (data.geoLocationType === "dma") {
      console.log(DMA_LIST)
      clonedResult.data.some((element: any) => {
        if (!DMA_LIST.map(item => item.name).includes(element[index])) {
          setError("form", {
            type: "manual",
            message: "Unsupported DMA value : " + element[index],
          });
          validationResult = false;
          return true;
        }
        return false;
      });
    } else {
      clonedResult.data.some((element: any) => {
        if (!REGIONS_LIST.map(item => item.name).includes(element[index])) {
          setError("form", {
            type: "manual",
            message: "Unsupported Region value : " + element[index],
          });
          validationResult = false;
          return true;
        }
        return false;
      });
    }
    return validationResult;
  }

  const validationCallback = (data: any, setError: any) => {
    console.log("Form Data", data);
    return new Promise<boolean>((resolve, reject) => {
      const fileInput = data.dataFile && data.dataFile[0];
      if (fileInput) {
        Papa.parse(fileInput, {
          complete: (results: any) => {
            if (data.geoLocation) {
              resolve(validateRegion(results, data, setError));
            }
            if (data.age) {
              resolve(validateAge(results, data, setError));
            }
            if (data.locale) {
              resolve(validateLocale(results, data, setError));
            }
            if (data.gender) {
              resolve(validateGender(results, data, setError));
            }
            if (data.devicePlatform) {
              resolve(validateDevicePlatform(results, data, setError));
            }
            if (data.userOs) {
              resolve(validateUserOs(results, data, setError));
            }
            if (data.userDevice) {
              resolve(validateUserDevice(results, data, setError));
            }
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
            setError("form", {
              type: "manual",
              message: "Error parsing CSV file.",
            });
            resolve(false);
          }
        });
      } else {
        setError("form", {
          type: "manual",
          message: "Error Parsing CSV File",
        });
        resolve(false);
      }
    });
  }


  return (
    <NavBar>
      <div className="h-full flex flex-col justify-center font-mono">
        <div className="w-full flex justify-center">
          <div className="md:w-7/12 w-full">
            <div className="text-center font-bold text-2xl my-10">
              Create Bid Multiplier Job
            </div>
            <DynamicForm
              formName="Bid Optimizer Import Form"
              formSchema={bidOptimizerSchema}
              onSubmit={onSubmit}
              onSubmitText="Create Job"
              isLoading={isLoading}
              validationCallback={validationCallback}
            />
            {isAlert && (
              <div
                role="alert"
                className="alert alert-success absolute bottom-8 right-8 w-auto"
              >
                <span className="ml-2">Job Config created successfully!</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </NavBar>
  );
};

export default BidOptimizer;
