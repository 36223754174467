import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption, SeriesOption } from "echarts";

export interface LineData {
  name: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}

export interface LineChartProps {
  labels: string[];
  dataSets: LineData[];
  xAxisTitle: string;
  yAxisTitle: string;
}

const LineChart: React.FC<LineChartProps> = ({
  labels,
  dataSets,
  xAxisTitle,
  yAxisTitle,
}) => {
  // Formatter for y-axis: Format large numbers (convert to K or M)
  const formatYAxisLabel = (value: number) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M"; // Convert millions to M
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K"; // Convert thousands to K
    } else {
      return value.toString(); // Keep smaller numbers as is
    }
  };

  // Formatter for x-axis: Replace underscores with spaces and capitalize words
  const formatXAxisLabel = (label: string) => {
    return label
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Formatter for axis titles: Replace underscores with spaces and capitalize
  const formatAxisTitle = (title: string) => {
    return title
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const series: SeriesOption[] = dataSets.map((dataSet) => ({
    name: dataSet.name,
    type: "line",
    data: dataSet.data,
    lineStyle: {
      color: dataSet.borderColor,
    },
    itemStyle: {
      color: dataSet.borderColor,
    },
    areaStyle: {
      color: dataSet.backgroundColor,
      opacity: 0.5,
    },
    label: {
      show: false, // Disable the display of values on the points
    },
  }));

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: dataSets.map((dataSet) => dataSet.name),
      top: "top",
    },
    grid: {
      left: '100px',  // Add more space for y-axis labels
      right: '20px',
      bottom: '100px',  // Add more space for x-axis labels
      top: '50px',
    },
    xAxis: {
      type: "category",
      data: labels,
      name: formatAxisTitle(xAxisTitle), // Apply formatted x-axis title
      nameLocation: "middle",
      nameGap: 70, // Increased gap between x-axis title and labels to prevent overlap
      nameTextStyle: {
        fontSize: 16, // Increased font size for x-axis title
      },
      axisLabel: {
        formatter: (value: string) => formatXAxisLabel(value), // Apply custom x-axis label formatting
        rotate: 45, // Rotate the x-axis labels by 45 degrees
        fontSize: 14, // Keep font size for x-axis labels
      },
    },
    yAxis: {
      type: "value",
      name: formatAxisTitle(yAxisTitle), // Apply formatted y-axis title
      nameLocation: "middle", // Move the label to the middle of the y-axis
      nameGap: 70, // Increased gap between y-axis title and values to prevent overlap
      nameRotate: 90, // Rotate the y-axis label to be vertical
      nameTextStyle: {
        fontSize: 16, // Increased font size for y-axis title
      },
      axisLabel: {
        formatter: (value: number) => formatYAxisLabel(value), // Apply custom y-axis label formatting
        fontSize: 14, // Keep font size for y-axis labels
      },
    },
    series,
  };

  return (
    <ReactECharts option={option} style={{ height: "400px", width: "100%" }} />
  );
};

export default LineChart;
