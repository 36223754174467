import React from "react";

export interface TableColumn {
  key: string; // Unique key to identify the column
  label: string; // Display name of the column
}

export interface TableRecord {
  [key: string]: any;
}

export interface DynamicTableProps {
  columns: TableColumn[];
  records: TableRecord[];
}

const Table: React.FC<DynamicTableProps> = ({ columns, records }) => {
  const formatNumber = (value: any) => {
    if (typeof value === "number") {
      return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return value;
  };

  return (
    <div className="">
      <div className="overflow-x-auto">
        <table className="table table-zebra w-full">
          <thead>
            <tr className="text-base">
              {columns.map((column) => (
                <th key={column.key}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {records.map((record, index) => (
              <tr key={index}>
                {columns.map((column) => (
                  <td key={column.key}>{formatNumber(record[column.key])}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
