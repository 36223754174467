import React, { useState } from "react";
import { z } from "zod";
import DynamicForm, { ExtendedFieldConfig } from "../DynamicForm";
import NavBar from "../NavBar";
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { DataSourceTypes } from "../../constants/DataSourceTypes";

const shopifyDataSourceSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z
      .string()
      .min(1, "Name is required")
      .max(100, "Name cannot exceed 100 characters"),
    metadata: {
      type: "text", label: "Name", group: "row1",
      fieldInfo: "Name of the Job to identify in Connection list"
    },
  },
  shopName: {
    validation: z
      .string()
      .min(1, "Shop Name is required")
      .max(100, "Shop Name cannot exceed 100 characters"),
    metadata: {
      type: "text", label: "Shop Name", group: "row1",
      fieldInfo: "Name of the shop Ex: shopname.myshopify.com"
    },
  },
  clientId: {
    validation: z
      .string()
      .min(1, "Client Id is required")
      .max(100, "Client Id cannot exceed 100 characters"),
    metadata: {
      type: "text", label: "Client Id", group: "row2",
      fieldInfo: "Client Id present in shopify custom app section"
    },
  },
  clientSecret: {
    validation: z
      .string()
      .min(1, "Client Secret is required")
      .max(100, "Client Secret cannot exceed 100 characters"),
    metadata: {
      type: "text", label: "Client Secret", group: "row3",
      fieldInfo: "Client Secret present in shopify custom app section"
    },
  },
  accessToken: {
    validation: z
      .string()
      .min(1, "Access Token is required")
      .max(100, "Access Token cannot exceed 100 characters"),
    metadata: {
      type: "text", label: "Access Token", group: "row4",
      fieldInfo: "Access Token present in shopify custom app section"
    },
  },
};

const DataSourceConfigShopify: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isAlert, setAlert] = useState(false);
  const onSubmit = async (data: Record<string, any>) => {
    try {
      console.log(data);
      setLoading(true);

      const dataSourceConfigId = uuidv4();

      const payload = {
        dataSourceConfigId,
        name: data.name,
        dataSourceType: DataSourceTypes.SHOPIFY,
        dataSourceConfigMap: {
          clientId: data.clientId,
          clientSecret: data.clientSecret,
          accessToken: data.accessToken,
          shopName: data.shopName
        }
      };

      console.log(payload);

      // const payload = {
      //   dataSourceConfigId,
      //   name: "Test name",
      //   dataSourceType: DataSourceTypes.SHOPIFY,
      //   dataSourceConfigMap: {
      //     clientId: "f7fd08414bc647ee065a808ad2af805a",
      //     clientSecret: "d0f591f8175f81073ace439943f37861",
      //     accessToken: "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",
      //     shopName: "58eac4-2",
      //     sourceDefinitionId: "9da77001-af33-4bcd-be46-6252bf9342b9",
      //     workspaceId: "2249fe66-575d-48fc-be69-a6b1e74a97be",
      //   }
      // };

      // Send the POST request using Axios
      const response = await axiosInstance.post(
        "/api/dataSource",
        payload
      );
      handleAlert();

      setLoading(false);
      // navigate(-1);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handleAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 1000);
  };


  return (
    <NavBar>
      <div className="h-full flex flex-col justify-center font-mono">
        <div className="w-full flex justify-center">
          <div className="md:w-7/12 w-full">
            <div className="text-center font-bold text-2xl my-10">
              Create Shopify Data Source
            </div>
            <DynamicForm
              formName="Shopify Data Source Form"
              formSchema={shopifyDataSourceSchema}
              onSubmit={onSubmit}
              onSubmitText="Create Shopify Connection"
              isLoading={isLoading}
            />
            {isAlert && (
              <div
                role="alert"
                className="alert alert-success absolute bottom-8 right-8 w-auto"
              >
                <span className="ml-2">Job Config created successfully!</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </NavBar>
  );
};

export default DataSourceConfigShopify;
