import { TableColumn } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";
import NavBar from "../../../components/NavBar";
import { Link } from "react-router-dom";
import { DataSourceTypes } from "../../../constants/DataSourceTypes";

const columns: TableColumn[] = [
    { key: "name", label: "Name" },
    { key: "jobType", label: "Job Type" },
    { key: "sourceType", label: "Source Type" },
    { key: "jobVersion", label: "Job Version" },
    { key: "confidence", label: "Confidence" },
    { key: "status", label: "Status" },
];

const shopifyBreadCrumbs = [
    {
        name: "Home",
        url: "/",
    },
    {
        name: "Data Source",
        url: "/data-source/shopify",
    },
    {
        name: "Shopify",
        url: "/data-source/shopify",
    },
];

const ShopifyConnections: React.FC = () => {

    return (
        <NavBar>
            <div className="p-8 flex flex-col">
                <div className="flex justify-between">
                    <div className="text-sm breadcrumbs self-end">
                        <ul>
                            {shopifyBreadCrumbs.map((entity: any) => (
                                <li key={entity.name}>
                                    <Link to={entity.url} className="font-mono underline">
                                        {entity.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="self-end">
                        <Link to={`/data-source/create/${DataSourceTypes.SHOPIFY}`} className="w-40 mb-4">
                            <button className="btn w-full">Add Shopify Account</button>
                        </Link>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
        </NavBar>
    );
};

export default ShopifyConnections;
