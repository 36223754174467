import React from "react";
import { Link } from "react-router-dom";

interface CollapsableSideBarListProps {
  collapsableSideBarListItemList: CollapsableSideBarListItem[];
}

export interface CollapsableSideBarListItem {
  name: string;
  list: { name: string; url?: string }[];
}

const CollapsableSideBarList: React.FC<CollapsableSideBarListProps> = ({
  collapsableSideBarListItemList,
}) => {
  return (
    <ul className="menu p-4 w-96 min-h-full text-base-content border-r-2">
      {collapsableSideBarListItemList.map((entity, index) => (
        <li key={entity.name}>
          <details open>
            <summary>{entity.name}</summary>
            <ul>
              {entity.list.map((subListEntity) => (
                <li key={subListEntity.name}>
                  <Link to={subListEntity.url as string}>{subListEntity.name}</Link>
                </li>
              ))}
            </ul>
          </details>
        </li>
      ))}
    </ul>
  );
};

export default CollapsableSideBarList;
