import React, { useState } from "react";
import NavBar from "../NavBar";
import axiosInstance from "../../utils/axiosInstance";
import DynamicForm, { ExtendedFieldConfig } from "../DynamicForm";
import { z } from "zod";
import { Maximize2 } from "react-feather";
import LineChart, { LineData } from "../LineChart";
import Table, { TableColumn, TableRecord } from "../Table";
import Heatmap from "../HeatMap";
import { JobType } from "../../constants/JobTypes";
import { v4 as uuidv4 } from "uuid";
import {
  CORRELATION_MATRIX_API,
  IMPUTE_API,
  SEASONALITY_API,
  VARIABLE_SUMMARY_API,
  VARIATION_INFLATION_FACTOR_API,
} from "../../constants/API";
import Maximize from "../Maximize";
import TopLoadingBar from "../TopLoadingBar/TopLoadingBar";
import Alert, { AlertProps } from "../Alert";
import { useNavigate } from "react-router-dom";

const dropDownOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

let importExtendedFormSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z
      .string()
      .min(1, "Name is required")
      .max(100, "Name cannot exceed 100 characters"),
    metadata: {
      type: "text",
      label: "Name",
      group: "row1",
      fieldInfo: "Name of the Job to identify in Jobs list",
    },
  },
  mmmDataFile: {
    validation: z.any().refine((file) => file && file.length > 0, {
      message: "Data CSV File is required",
    }),
    metadata: {
      type: "file",
      label: "Data CSV File",
      group: "row7",
      accepts: ".csv",
      showIf: [],
      fieldInfo: "CSV file on which you want to run the model",
    },
  },
  responseVariable: {
    validation: z.string().min(1, "Please select response variable"),
    metadata: {
      type: "select",
      label: "Response Variable",
      options: [],
      dynamicOptions: {
        field: "mmmDataFile",
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables, Example: Sales",
    },
  },
  dateVariable: {
    validation: z.string().min(1, "Please select date variable"),
    metadata: {
      type: "select",
      label: "Date Variable",
      options: [],
      dynamicOptions: {
        field: "mmmDataFile",
      },
      group: "row8",
      showIf: [],
      fieldInfo: "Column name that has date for a record in you CSV file",
    },
  },
  dateFormat: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Date Format",
      options: [
        {
          label: "MM/DD/YYYY",
          value: "%m/%d/%Y",
        },
        {
          label: "MM/DD/YY",
          value: "%m/%d/%y",
        },
        {
          label: "YYYY-MM-DD",
          value: "%Y-%m-%d",
        },
        {
          label: "DD/MM/YYYY",
          value: "%d/%m/%Y",
        },
        {
          label: "DD/MM/YY",
          value: "%d/%m/%y",
        },
        {
          label: "YYYY/MM/DD",
          value: "%Y/%m/%d",
        },
        {
          label: "DD-MM-YYYY",
          value: "%d-%m-%Y",
        },
        {
          label: "DD-MM-YY",
          value: "%d-%m-%y",
        },
        {
          label: "YYYY.MM.DD",
          value: "%Y.%m.%d",
        },
        {
          label: "DD.MM.YYYY",
          value: "%d.%m.%Y",
        },
        {
          label: "MM-DD-YYYY",
          value: "%m-%d-%Y",
        },
        {
          label: "YYYY/MM/DD HH:MM:SS",
          value: "%Y/%m/%d %H:%M:%S",
        },
        {
          label: "DD/MM/YYYY HH:MM",
          value: "%d/%m/%Y %H:%M",
        },
        {
          label: "YYYY-MM-DDTHH:MM:SS",
          value: "%Y-%m-%dT%H:%M:%S",
        },
      ],
      group: "row8",
      showIf: [],
      fieldInfo: "Date format of the date column you have selected",
    },
  },
  spendVariable: {
    validation: z
      .array(dropDownOptionSchema)
      .min(1, "At least one spend variable must be selected"),
    metadata: {
      type: "multi-select",
      label: "Spend Variables",
      selectedOptions: [],
      dynamicOptions: {
        field: "mmmDataFile",
      },
      group: "row9",
      showIf: [],
      fieldInfo:
        "List of spend variables for Example: Google spend, Facebook Spend, etc.",
    },
  },
  impressionVariable: {
    validation: z
      .array(dropDownOptionSchema)
      .min(1, "At least one impression variable must be selected"),
    metadata: {
      type: "multi-select",
      label: "Impression Variables",
      selectedOptions: [],
      dynamicOptions: {
        field: "mmmDataFile",
      },
      group: "row9",
      showIf: [],
      fieldInfo:
        "List of impression variables for Example: Google Impression, Facebook Impression, etc.",
    },
  },
  nonMediaVariableVariable: {
    validation: z.any(),
    metadata: {
      type: "multi-select",
      label: "Non-Media Variables",
      selectedOptions: [],
      dynamicOptions: {
        field: "mmmDataFile",
      },
      group: "row9",
      showIf: [],
    },
  },
};

const edaExtendedFormSchema: Record<string, ExtendedFieldConfig> = {};

const featureExtendedFormSchema: Record<string, ExtendedFieldConfig> = {
  dependentVariable: {
    validation: z
      .array(dropDownOptionSchema)
      .min(1, "At least one dependent variable must be selected"),
    metadata: {
      type: "multi-select",
      label: "Dependent Variables",
      options: [],
      selectedOptions: [],
      group: "row9",
      showIf: [],
    },
  },
};

const variableSummaryColumns: TableColumn[] = [
  { key: "variable", label: "Variable" },
  { key: "mean", label: "Mean" },
  { key: "median", label: "Median" },
  { key: "missing_count_percentage", label: "Missing Count %" },
  { key: "standard_deviation", label: "Standard Deviation" },
];

const multiColinearityColumn: TableColumn[] = [
  { key: "variable", label: "Variable" },
  { key: "value", label: "Value" },
];

const JobConfigCreateMMM = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [seasonDataSet, setSeasonDataSet] = useState<LineData[]>([]);
  const [trendDataSet, setTrendDataSet] = useState<LineData[]>([]);
  const [seasonLabel, setSeasonLabel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState<AlertProps["type"]>("success");
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [heatMapData, setHeatMapData] = useState<any>(undefined);
  const [variableSummaryStatistic, setVariableSummaryStatistic] = useState<
    TableRecord[]
  >([]);
  const [multiColinearityRecords, setMultiColinearityRecords] = useState<
    TableRecord[]
  >([]);

  const [importMMMFormState, setImportMMMFormState] = useState<any>([]);

  const submitImportForm = async (data: Record<string, any>) => {
    try {
      setLoading(true);
      setImportMMMFormState(data);
      Object.keys(data).forEach((key) => {
        if (importExtendedFormSchema[key].metadata.type === "multi-select") {
          importExtendedFormSchema[key].metadata.selectedOptions = data[key];
        } else {
          importExtendedFormSchema[key].metadata.defaultValue = data[key];
        }
      });

      // Create a new FormData object
      const seasonalityFormData = new FormData();
      seasonalityFormData.append("date_column", data.dateVariable);
      seasonalityFormData.append(
        "response_variable_column",
        data.responseVariable
      );
      seasonalityFormData.append("csv_file", data.mmmDataFile[0]);
      seasonalityFormData.append("yearly_seasonality", "true");

      // Send the POST request using Axios
      const seasonalityResponse = await axiosInstance.post(
        SEASONALITY_API,
        seasonalityFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      const seasonalityData = seasonalityResponse.data.seasonality_data;

      const labels = seasonalityData.map(
        (item: any) => item[data.dateVariable]
      );
      const seasonData = seasonalityData.map((item: any) => item.Season);
      const trendData = seasonalityData.map((item: any) => item.Trend);

      setSeasonLabel(labels);

      setSeasonDataSet([
        {
          name: "Season",
          data: seasonData,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.3)",
        },
      ]);

      setTrendDataSet([
        {
          name: "Trend",
          data: trendData,
          borderColor: "rgba(255, 99, 132, 1)",
          backgroundColor: "rgba(255, 99, 132, 0.3)",
        },
      ]);

      const variableSummaryFormData = new FormData();
      variableSummaryFormData.append("csv_file", data.mmmDataFile[0]);
      variableSummaryFormData.append("date_column", data.dateVariable);
      variableSummaryFormData.append("date_format", data.dateFormat);
      variableSummaryFormData.append("columns", data.responseVariable);
      const columns = [
        ...data.spendVariable,
        ...data.impressionVariable,
        ...data.nonMediaVariableVariable,
      ];
      columns.forEach((column) =>
        variableSummaryFormData.append("columns", column.value)
      );

      // Send the POST request using Axios
      const variableSummaryStatisticResponse = await axiosInstance.post(
        VARIABLE_SUMMARY_API,
        variableSummaryFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      setVariableSummaryStatistic(
        variableSummaryStatisticResponse.data.variable_summary
      );

      [
        ...data.spendVariable.map((item: any) => item.value),
        data.responseVariable,
        ...(data.nonMediaVariableVariable === ""
          ? []
          : data.nonMediaVariableVariable.map((item: any) => item.value)),
        ...data.impressionVariable.map((item: any) => item.value),
      ].forEach((field, index) => {
        edaExtendedFormSchema[field] = {
          validation: z.any(),
          metadata: {
            type: "select",
            label: field,
            group: "row" + (index % 4),
            options: [
              {
                label: "Mean",
                value: "mean",
              },
              {
                label: "Last Missing Value",
                value: "lastMissingValue",
              },
              {
                label: "Median",
                value: "median",
              },
            ],
            showIf: [],
          },
        };
      });

      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setAlertMessage("Failed to import data.");
      setAlertType("error");
    }
  };

  const submitEDAForm = async (formData: Record<string, any>) => {
    try {
      setLoading(true);
      console.log("eda form data: ", formData);
      featureExtendedFormSchema.dependentVariable.metadata.options = [
        ...importMMMFormState.spendVariable,
        ...importMMMFormState.nonMediaVariableVariable,
        ...importMMMFormState.impressionVariable,
      ];
      const imputeFormData = new FormData();
      imputeFormData.append("csv_file", importMMMFormState.mmmDataFile[0]);
      imputeFormData.append("imputation_methods", JSON.stringify(formData));

      // Send the POST request using Axios
      const imputedDataResponse = await axiosInstance.post(
        IMPUTE_API,
        imputeFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      const correlationMatrixForm = new FormData();

      correlationMatrixForm.append(
        "csv_file",
        importMMMFormState.mmmDataFile[0]
      );

      correlationMatrixForm.append(
        "impression_variables",
        importMMMFormState.responseVariable
      );
      importMMMFormState.spendVariable.map((item: any) => {
        correlationMatrixForm.append("spend_variables", item.value);
      });
      if (importMMMFormState.nonMediaVariableVariable instanceof Array) {
        importMMMFormState.nonMediaVariableVariable.map((item: any) => {
          correlationMatrixForm.append("non_media_variables", item.value);
        });
      }
      importMMMFormState.impressionVariable.map((item: any) => {
        correlationMatrixForm.append("impression_variables", item.value);
      });

      const correlationMatrixResponse = await axiosInstance.post(
        CORRELATION_MATRIX_API,
        correlationMatrixForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      // Extracting keys and values from the response
      const correlationMatrix =
        correlationMatrixResponse.data.correlation_matrix;
      const xLabels = Object.keys(correlationMatrix);
      const yLabels = xLabels;
      const zData = xLabels.map((x) =>
        yLabels.map((y) => correlationMatrix[x][y])
      );

      // Setting the heatmap data
      setHeatMapData({
        xLabels,
        yLabels,
        zData,
      });

      const multiColinearityForm = new FormData();
      multiColinearityForm.append(
        "csv_file",
        importMMMFormState.mmmDataFile[0]
      );

      importMMMFormState.spendVariable.map((item: any) => {
        multiColinearityForm.append("spend_variables", item.value);
      });
      if (importMMMFormState.nonMediaVariableVariable instanceof Array) {
        importMMMFormState.nonMediaVariableVariable.map((item: any) => {
          multiColinearityForm.append("non_media_variables", item.value);
        });
      }

      importMMMFormState.impressionVariable.map((item: any) => {
        multiColinearityForm.append("impression_variables", item.value);
      });

      const multiColinearityResponse = await axiosInstance.post(
        VARIATION_INFLATION_FACTOR_API,
        multiColinearityForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      if (typeof multiColinearityResponse.data === "string") {
        try {
          multiColinearityResponse.data = JSON.parse(
            multiColinearityResponse.data.replaceAll("Infinity", "1")
          );
        } catch (error) {
          console.error("Failed to parse JSON string:", error);
        }
      }

      const records: TableRecord[] = [];
      Object.keys(multiColinearityResponse.data.variance_inflation_factors).map(
        (key) => {
          records.push({
            variable: key,
            value:
              multiColinearityResponse.data.variance_inflation_factors[key],
          });
        }
      );
      setMultiColinearityRecords(records);

      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlertMessage("Failed to clean data.");
      setAlertType("error");
    }
  };

  const submitFeatureForm = async (formData: Record<string, any>) => {
    try {
      setLoading(true);
      const dependentVariable = formData.dependentVariable.map(
        (item: any) => item.value
      );
      const mediaVariable = importMMMFormState.spendVariable.map(
        (item: any) => item.value
      );
      const nonMediaVariable = importMMMFormState.nonMediaVariableVariable.map(
        (item: any) => item.value
      );
      const impressionVariable = importMMMFormState.impressionVariable.map(
        (item: any) => item.value
      );

      const jobId = uuidv4();
      const mmmFormData = new FormData();
      mmmFormData.append("file", importMMMFormState.mmmDataFile[0]);
      const mmmFilePath = jobId + "/input/mmm_data.csv";
      mmmFormData.append("path", mmmFilePath);
      await axiosInstance.post("/api/s3/upload", mmmFormData);

      const payload = {
        jobConfigId: jobId,
        name: importMMMFormState.name,
        jobType: JobType.MarketingMixModel,
        dataSourceConfig: {
          dataSourceType: "FILE_CSV",
        },
        dataTransformationConfig: {},
        dataMappingConfig: {
          mappings: {
            mmm: {
              columnMapping: {
                media_variables: mediaVariable.filter((item: any) =>
                  dependentVariable.includes(item)
                ),
                non_media_variables: nonMediaVariable.filter((item: any) =>
                  dependentVariable.includes(item)
                ),
                impression_variables: impressionVariable.filter((item: any) =>
                  dependentVariable.includes(item)
                ),
                target_variables: [importMMMFormState.responseVariable],
                date_variables: [importMMMFormState.dateVariable],
              },
            },
          },
        },
        modelVersion: "V1",
        modelParameterConfig: {
          parameters: {
            dateFormat: importMMMFormState.dateFormat,
          },
        },
        modelOutputConfig: {},
      };

      // Send the POST request using Axios
      const response = await axiosInstance.post("/api/jobConfig", payload);

      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlertMessage("Failed to create Job Configuration.");
      setAlertType("error");
    }
  };
  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps = [
    {
      title: "Import Data",
      content: (
        <div className="py-8 px-16">
          <DynamicForm
            formName="Import Data MMM Form"
            formSchema={importExtendedFormSchema}
            onSubmit={submitImportForm}
            onSubmitText={"🗃️ Import Data"}
            // onPrevious={prevStep}
            // onPreviousText={"Previous"}
            isLoading={loading}
          />
        </div>
      ),
    },
    {
      title: "Exploratory Data Analysis",
      content: (
        <>
          <div className="flex justify-between">
            <div className="card card-compact w-1/2 bg-base-100 shadow-xl border-2 mt-6 mr-6">
              <div className="card-body border-b-2 flex flex-row justify-between">
                <h2 className="card-title">Seasonality</h2>
                <button className="btn btn-square btn-sm">
                  <Maximize2 width={18} />
                </button>
              </div>
              <div className="overflow-auto">
                <LineChart
                  labels={seasonLabel}
                  dataSets={seasonDataSet}
                  yAxisTitle="Level Component For Sale"
                  xAxisTitle="Date"
                />
              </div>
            </div>
            <div className="card card-compact w-1/2 bg-base-100 shadow-xl border-2 mt-6">
              <div className="card-body border-b-2 flex flex-row justify-between">
                <h2 className="card-title">Trend</h2>
                <button className="btn btn-square btn-sm">
                  <Maximize2 width={18} />
                </button>
              </div>
              <div className="overflow-auto">
                <LineChart
                  labels={seasonLabel}
                  dataSets={trendDataSet}
                  xAxisTitle="Date"
                  yAxisTitle="Level Component For Sale"
                />
              </div>
            </div>
          </div>
          <div className="card card-compact w-auto bg-base-100 shadow-xl border-2 mt-6 overflow-hidden">
            <div className="card-body border-b-2 flex flex-row justify-between">
              <h2 className="card-title">Variable Summary Statistics</h2>
              <button className="btn btn-square btn-sm">
                <Maximize2 width={18} />
              </button>
            </div>
            <div className="overflow-auto">
              <Table
                columns={variableSummaryColumns}
                records={variableSummaryStatistic}
              />
            </div>
          </div>
          <div>
            <Maximize title={"Imupte Missing Values"}>
              <DynamicForm
                formName="Impute Missing Value MMM Form"
                formSchema={edaExtendedFormSchema}
                onSubmit={submitEDAForm}
                onSubmitText={"🛠️ Impute Missing Data"}
                // onPrevious={prevStep}
                // onPreviousText={"Previous"}
                isLoading={loading}
              />
            </Maximize>
          </div>
        </>
      ),
    },
    {
      title: "Feature/Model Selection",
      content: (
        <>
          <div className="mx-8">
            <Maximize title={"Correlation Matrix"}>
              <Heatmap data={heatMapData} />
            </Maximize>
          </div>
          <div className="card card-compact w-auto bg-base-100 shadow-xl border-2 mt-6 mx-8">
            <div className="card-body border-b-2 flex flex-row justify-between">
              <h2 className="card-title">Multi Colinearity Check</h2>
              <button className="btn btn-square btn-sm">
                <Maximize2 width={18} />
              </button>
            </div>
            <div className="overflow-auto">
              <Table
                columns={multiColinearityColumn}
                records={multiColinearityRecords}
              />
            </div>
          </div>
          <div>
            <DynamicForm
              formName="Create MMM Job"
              formSchema={featureExtendedFormSchema}
              onSubmit={submitFeatureForm}
              onSubmitText={"📜 Create Job Configuration"}
              // onPrevious={prevStep}
              // onPreviousText={"Previous"}
              isLoading={loading}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <NavBar>
      <div className={loading ? "" : "hidden"}>
        <TopLoadingBar></TopLoadingBar>
      </div>
      <div className="container mx-auto p-12">
        <p className="text-center pb-8 text-lg font-semibold">
          Create Job - Marketing Mix Model
        </p>
        <div className="steps w-full flex justify-between items-center mb-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`step flex-1 text-center  ${
                index <= currentStep ? "step-primary" : ""
              }`}
            >
              <div>{step.title}</div>
            </div>
          ))}
        </div>
        <div className="content mb-4">
          <div>{steps[currentStep].content}</div>
        </div>
      </div>
      {alertMessage && (
        <Alert
          message={alertMessage}
          type={alertType}
          callback={() => {
            setAlertMessage(undefined);
          }}
        ></Alert>
      )}
    </NavBar>
  );
};

export default JobConfigCreateMMM;
