import { Maximize2 } from "react-feather";

export interface MaximizeProps {
    title: string;
    children?: React.ReactNode;
}

const Maximize: React.FC<MaximizeProps> = ({ title, children }) => {
    return (
        <div className="card card-compact w-full bg-base-100 border-2 mt-6 mr-6">
            <div className="card-body border-b-2 flex flex-row justify-between">
                <h2 className="card-title">{title}</h2>
                <button className="btn btn-square btn-sm">
                    <Maximize2 width={18} />
                </button>
            </div>
            <div className="overflow-auto">
                {children}
            </div>
        </div>
    );
};

export default Maximize;
