import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import NextBestAction from "./pages/optimization/next-best-action";
import "./index.css";
import Login from "./pages/auth/login";
import GeoLift from "./pages/optimization/geo-lift";
import Users from "./pages/users";
import CreateUser from "./pages/users/create-user";
import CreateTenant from "./pages/auth/create-tenant";
import JobCreate from "./pages/job-create";
import MarketingMixModel from "./pages/measurement/marketing-mix-model";
import MMMDashboard from "./pages/measurement/marketing-mix-model/[id]";
import BidOptimizer from "./pages/optimization/bid-optimizer";
import BidOptimizerDashboard from "./pages/optimization/bid-optimizer/[id]";
//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";
import { jwtDecode } from "jwt-decode";
import ShopifyConnections from "./pages/integration/shopify";
import DataSourceCreate from "./pages/data-source-create";
import UpdateUser from "./pages/users/update-user";

// Near entry of your product, init Mixpanel
mixpanel.init("590857ca7f2572c75c7f1c443e749dfc", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

if (localStorage.getItem("authToken") != null) {
  const decoded = jwtDecode(localStorage.getItem("authToken") as string);
  mixpanel.identify(decoded.sub);
  mixpanel.people.set(decoded);
}



function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/create-tenant" element={<CreateTenant />} />

      <Route
        path="/data-source/shopify"
        element={<ShopifyConnections />}
      />

      <Route
        path="/optimization/next-best-action"
        element={<NextBestAction />}
      />
      <Route path="/optimization/geo-lift" element={<GeoLift />} />

      <Route path="/optimization/bid-optimizer" element={<BidOptimizer />} />

      <Route
        path="/optimization/bid-optimizer/:id"
        Component={BidOptimizerDashboard}
      />

      <Route
        path="/measurement/marketing-mix-model"
        element={<MarketingMixModel />}
      />

      <Route
        path="/measurement/marketing-mix-model/:id"
        Component={MMMDashboard}
      />

      <Route path="/jobs/:jobType" element={<JobCreate />} />
      <Route path="/data-source/create/:dataSourceTypes" element={<DataSourceCreate />} />


      <Route path="/users" element={<Users />} />
      <Route path="/users/create-user" element={<CreateUser />} />
      <Route path="/users/update-user/:userId" element={<UpdateUser />} />
    </Routes>
  );
}

export default App;
