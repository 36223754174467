import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import NavBar from "../../../../components/NavBar";
import DynamicForm, {
  ExtendedFieldConfig,
} from "../../../../components/DynamicForm";
import { z } from "zod";
import PieChart from "../../../../components/PieChart";
import WaterFallChart from "../../../../components/WaterFallChart";
import axiosInstance from "../../../../utils/axiosInstance";
import LineChart, {
  LineChartProps,
  LineData,
} from "../../../../components/LineChart";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import Maximize from "../../../../components/Maximize";
import GradientStackedAreaChart from "../../../../components/GradientStackedAreaChart";

const initialBreadcrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/marketing-mix-model",
  },
  {
    name: "Marketing Mix Model",
    url: "/measurement/marketing-mix-model",
  },
];

interface MMMDashboardProps {
  details: any;
  result: any;
}

const generateSaturationCharts = (response: any) => {
  const colors = [
    "rgba(75, 192, 192, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];

  const charts: any[] = [];
  const allKeys = Object.keys(response);

  allKeys.forEach((key, index) => {
    if (key.startsWith("Saturation_")) {
      const data = response[key];
      const datasetName = key
        .replaceAll("Saturation_", "")
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .replaceAll(/_/g, " ");
      const color = colors[index % colors.length];
      const labels = data.map((item: any) => item.Spends);
      const lineData = data.map((item: any) => {
        return item[key.replaceAll("Saturation_", "")];
      });
      const chartProps: LineChartProps = {
        labels,
        dataSets: [
          {
            name: datasetName,
            data: lineData,
            borderColor: color,
            backgroundColor: color.replaceAll("1)", "0.3)"),
          },
        ],
        xAxisTitle: "Spends",
        yAxisTitle: datasetName,
      };

      charts.push(
        <div className="" style={{ width: "calc(50% - 8px)" }}>
          <div className="w-full">
            <Maximize title={datasetName}>
              <LineChart key={key} {...chartProps} />
            </Maximize>
          </div>
        </div>
      );
    }
  });

  return charts;
};

let importExtendedFormSchema: Record<string, ExtendedFieldConfig> = {
  amount: {
    validation: z.string().min(1, "Amount is required"),
    metadata: { type: "text", label: "Amount", group: "row1" },
  },
  weeks: {
    validation: z.string().min(1, "Weeks is required"),
    metadata: { type: "text", label: "Weeks", group: "row1" },
  },
};

const MMMDashboard: React.FC = () => {
  let [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const { id } = useParams<{ id: string }>(); // Extract UUID from URL

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs: any) => {
      if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === id)) {
        return [
          ...prevBreadcrumbs,
          { name: id, url: `/measurement/marketing-mix-model/${id}` },
        ];
      }
      return prevBreadcrumbs;
    });
  }, [id]);

  const [contributionChartData, setContributionChartData] = useState([]);
  const [contributionChartLabel, setContributionChartLabel] = useState([]);

  const [saturationCharts, setSaturationCharts] = useState([]);

  const [yearlyOptimizedSpendChartData, setYearlyOptimizedSpendChartData] =
    useState([]);
  const [yearlyOptimizedSpendChartLabel, setYearlyOptimizedSpendChartLabel] =
    useState([]);

  const [actualPredictedLabels, setActualPredictedLabels] = useState([]);
  const [actualSales, setActualSales] = useState([]);
  const [predictedSales, setPredictedSales] = useState([]);

  const [waterFallChartLabel, setWaterFallChartLabel] = useState([]);
  const [waterFallChartData, setWaterFallChartData] = useState([]);

  const [salesDecompositionLabel, setSalesDecompositionLabel] = useState([]);
  const [salesDecompositionData, setSalesDecompositionData] = useState([]);

  const [elasticityColumn, setElasticityColumn] = useState<TableColumn[]>([]);
  const [elasticityRecord, setElasticityRecord] = useState<TableRecord[]>([]);

  useEffect(() => {
    axiosInstance.get(`/api/mmm/output/${id}`).then((response) => {
      let outputJson = response.data.result;
      if (typeof outputJson === "string") {
        try {
          outputJson = JSON.parse(outputJson);
          console.log("response mmm: ", outputJson);
        } catch (error) {
          console.error("Failed to parse JSON string:", error);
        }
      }
      setSalesDecompositionData(outputJson["Sales_Decomposition"]);
      setSalesDecompositionLabel(outputJson["Sales_Decomposition"].map((item: any, index: any) => index));
      const charts = generateSaturationCharts(outputJson);

      setSaturationCharts(charts as any);

      setContributionChartData(
        outputJson["Final_Optimizer"].map((item: any) =>
          Number.parseFloat(item.Contribution)
        )
      );
      setContributionChartLabel(
        outputJson["Final_Optimizer"].map((item: any) => item.Media)
      );

      setYearlyOptimizedSpendChartData(
        outputJson["Final_Optimizer"].map((item: any) =>
          Number.parseFloat(item.Yearly_Optimize_Spend)
        )
      );
      setYearlyOptimizedSpendChartLabel(
        outputJson["Final_Optimizer"].map((item: any) => item.Media)
      );

      setActualPredictedLabels(
        outputJson["Actual_vs_predicted"]
          .map((item: any) => {
            const dateKey = Object.keys(item).find(
              (key) => key.trim() === "Date"
            );
            if (dateKey) {
              return item[dateKey];
            }
            return null;
          })
          .filter((date: any) => date !== null)
      );
      setActualSales(
        outputJson["Actual_vs_predicted"].map((item: any) => item.Sales)
      );
      setPredictedSales(
        outputJson["Actual_vs_predicted"].map((item: any) => item.prediction)
      );

      setWaterFallChartLabel(
        outputJson.Contributions_chart.map((item: any) => item.Variables)
      );
      setWaterFallChartData(
        outputJson.Contributions_chart.map((item: any) =>
          Number.parseFloat(item.Contributions)
        )
      );

      console.log(outputJson.Elasticity_Report[0]);
      setElasticityColumn(
        Object.keys(outputJson.Elasticity_Report[0]).sort((a, b) => {
          if (a === "MediaChannel") return -1; // Move "MediaChannel" to the first position
          if (b === "MediaChannel") return 1;
          return 0; // Maintain order for other keys
        }).map((key) => ({
          key: key.trim(),
          label: key.trim().replaceAll("_", " "),
        }))
      );
      setElasticityRecord(
        outputJson.Elasticity_Report.map((item: any) => {
          const record: TableRecord = {};
          Object.keys(item).forEach((key) => {
            // Replace underscores with spaces and capitalize each word for the key
            const formattedKey = key
              .trim()
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');

            // Apply the same transformation to the value if it's a string
            let formattedValue = item[key];
            if (typeof formattedValue === 'string') {
              formattedValue = formattedValue
                .trim()
                .split('_')
                .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            }

            record[formattedKey] = formattedValue;
          });
          return record;
        })
      );

    });
  }, [id]);

  const waterfallData = {
    labels: waterFallChartLabel,
    datasets: [
      {
        label: "Contributions",
        data: waterFallChartData,
      },
    ],
  };

  const twoLineChartData: LineChartProps = {
    labels: actualPredictedLabels,
    dataSets: [
      {
        name: "Actual Sales",
        data: actualSales,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
      },
      {
        name: "Predicted Sales",
        data: predictedSales,
        borderColor: "#0000ff",
        backgroundColor: "rgba(0, 0, 255, 0.3)",
      },
    ],
    xAxisTitle: "Days",
    yAxisTitle: "Sales",
  };

  return (
    <NavBar>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between">
          <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadcrumbs.map((entity) => (
                <li key={entity.name}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* <div className="divider"></div> */}
        {/* <div className="text-center text-xl font-semibold">
          Budget Optimizer
        </div>
        <div className="w-full mt-2 flex justify-center items-center">
          <div className="w-2/3">
            <DynamicForm
              formSchema={importExtendedFormSchema}
              onSubmit={() => {}}
              onSubmitText={"Calculate"}
              isLoading={false}
            />
          </div>
        </div> */}
        <div className="divider"></div>
        <div className="mx-8">
          <Maximize title="Accumulated Waterfall Chart">
            <div className="w-full h-[700px]">
              <WaterFallChart
                data={waterFallChartData}
                labels={waterFallChartLabel}
              />
            </div>
          </Maximize>
        </div>
        <div className="divider"></div>
        <div className="mx-8">
          <Maximize title="Accumulated Waterfall Chart">
            <div className="w-full h-[700px]">
              <WaterFallChart
                data={waterFallChartData}
                labels={waterFallChartLabel}
                isBase100={true}
              />
            </div>
          </Maximize>
        </div>
        <div className="mx-8">
          <Maximize title="Actual Vs Predicted">
            <div className="w-full pt-4">
              <LineChart {...twoLineChartData} />
            </div>
          </Maximize>
        </div>
        <div className="mx-8">
          <Maximize title="Sales Decomposition">
            <div className="w-full pt-4">
              {salesDecompositionData.length && (
                <GradientStackedAreaChart
                  data={salesDecompositionData}
                  labels={salesDecompositionLabel}
                />
              )}
            </div>
          </Maximize>
        </div>

        <div className="mx-8">
          <Maximize title="Elasticity Report">
            <div className="w-full px-2">
              <Table columns={elasticityColumn} records={elasticityRecord} />
            </div>
          </Maximize>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-center my-8">
            Saturation Curves
          </h1>
          <div className="flex flex-wrap mx-8 justify-between">
            {saturationCharts}
          </div>
        </div>
        <h1 className="text-2xl font-bold text-center mt-16 mb-8">
          Optimization Charts
        </h1>
        <div className="flex justify-between mx-8">
          <div className="w-full">
            <Maximize title="Yearly Optimized Spend Chart">
              <div className="mt-8">
                <PieChart
                  data={yearlyOptimizedSpendChartData}
                  labels={yearlyOptimizedSpendChartLabel}
                />
              </div>
            </Maximize>
          </div>
        </div>
        <div className="flex justify-between mx-8">
          <div className="w-full" >
            <Maximize title="Contribution Chart">
              <div className="mt-8">
                <PieChart
                  data={contributionChartData}
                  labels={contributionChartLabel}
                />
              </div>
            </Maximize>
          </div>
        </div>
      </div>
    </NavBar>
  );
};

export default MMMDashboard;
