import React from "react";
import UpdateUserForm from "./UpdateUser";
import NavBar from "../../../components/NavBar";

const UpdateUser = () => {
  return (
    <NavBar>
      <div className="h-full flex flex-col justify-center font-mono">
        <div className="w-full flex justify-center">
          <div className="md:w-1/2 w-full">
            <div className="text-center font-bold text-2xl my-10 mb-4">
              Create User
            </div>
            <UpdateUserForm />
          </div>
        </div>
      </div>
    </NavBar>
  );
};

export default UpdateUser;
